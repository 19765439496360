import React from "react";
import { Link } from "gatsby";

const Filter = ({
  items,
  unfilteredUri,
  active,
  workId,
  primary,
  secondary,
  children,
}) => {
  return (
    <ul className="flex flex-wrap gap-x-x-sm md:gap-x-[40px] gap-y-[15px] mb-[30px]">
      <li>
        <strong className="typo-work-filter font-bold">{children}</strong>
      </li>
      {items.map((item) => {
        if (!item.projects.nodes.length) return null;

        let uriAddOn =
          (primary && primary !== "*" ? primary + "/" : "") +
          (item.slug !== active ? item.slug : "") +
          (secondary && secondary !== "*" ? "/" + secondary : "");

        let uri = unfilteredUri + uriAddOn + "/#" + workId;

        return (
          <li className="typo-work-filter cursor-pointer" key={item.id}>
            <Link
              className={`block work-filter-link ${
                active === item.slug ? "active" : ""
              }`}
              to={uri.replace("//", "/")}
            >
              {item.name}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default Filter;
