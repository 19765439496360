import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";

const CenteredContent = ({ data }) => {
  return (
    <section
      id={convertId(data.name)}
      className={`centered-content section bg-white px-x py-y-lg lg:pt-[86px] lg:pb-[100px] ${data.cssClasses}`}
    >
      <div className="max-w-[562px] mx-auto text-center">
        {data.title && (
          <Content className="typo-title mb-[39px]">{data.title}</Content>
        )}
        {data.subTitle && (
          <Content className="typo-content-strong mb-[14px]">
            {data.subTitle}
          </Content>
        )}
        {data.content && (
          <Content className="typo-paragraph-text text-gray-3">
            {data.content}
          </Content>
        )}
      </div>
    </section>
  );
};

export const query = graphql`
  fragment CenteredContent on WpPage_Sections_Sections_CenteredContent {
    fieldGroupName
    name
    cssClasses
    title
    subTitle
    content
  }
  fragment ReusableCenteredContent on WpReusableSection_Sections_Sections_CenteredContent {
    fieldGroupName
    name
    cssClasses
    title
    subTitle
    content
  }
  fragment ProjectCenteredContent on WpProject_Sections_Sections_CenteredContent {
    fieldGroupName
    name
    cssClasses
    title
    subTitle
    content
  }
`;

export default CenteredContent;
