import React from "react";
import { graphql } from "gatsby";
import WorkElem from "../components/work/work";
import Banner from "../components/sections/banner";
import CenteredContent from "../components/sections/centered-content";
import Seo from "../components/utilities/seo";

const Work = ({
  data: {
    allWpProject: { edges: projects },
    wpPage: { work: page, title: title, page: pageSeo },
    wpService,
    wp: {
      themeSettings: { themeSettings },
    },
  },
  pageContext: {
    totalPages,
    currentPage,
    baseUri,
    unfilteredUri,
    primaryFilter,
    secondaryFilter,
  },
  pageContext,
  location,
}) => {
  const servicePage = wpService?.work;
  const serviceSEO = wpService?.page;

  const banner = {
    name: "work banner",
    background: servicePage?.bannerBackground
      ? servicePage.bannerBackground
      : page.bannerBackground,
    subTitle: servicePage?.bannerSubTitle
      ? servicePage.bannerSubTitle
      : page.bannerSubTitle,
    title: servicePage?.bannerTitle
      ? servicePage.bannerTitle
      : page.bannerTitle,
    pageContext: pageContext,
    location: location,
  };

  const centeredContent = {
    name: "work centered content",
    title: servicePage?.centeredContentTitle
      ? servicePage.centeredContentTitle
      : page.centeredContentTitle,
    subTitle: servicePage?.centeredContentSubTitle
      ? servicePage.centeredContentSubTitle
      : page.centeredContentSubTitle,
    content: servicePage?.centeredContent
      ? servicePage.centeredContent
      : page.centeredContent,
  };

  const workId = "projects";

  const work = {
    name: workId,
    title: servicePage?.workTitle ? servicePage.workTitle : page.workTitle,
    content: servicePage?.workContent
      ? servicePage.workContent
      : page.workContent,
    btmTitle: servicePage?.workBottomTitle
      ? servicePage.workBottomTitle
      : page.workBottomTitle,
    btmSubtitle: servicePage?.workBottomSubtitle
      ? servicePage.workBottomSubtitle
      : page.workBottomSubtitle,
    btmContent: servicePage?.workBottomContent
      ? servicePage.workBottomContent
      : page.workBottomContent,
  };

  return (
    <>
      <Seo
        page={serviceSEO ? serviceSEO : pageSeo}
        themeSettings={themeSettings}
        title={title}
        currentPage={currentPage}
      />
      <Banner data={banner} location={location} pageContext={pageContext} />
      <CenteredContent data={centeredContent} />
      <WorkElem
        data={work}
        projects={projects}
        totalPages={totalPages}
        currentPage={currentPage}
        baseUri={baseUri}
        unfilteredUri={unfilteredUri}
        primaryFilter={primaryFilter}
        secondaryFilter={secondaryFilter}
        workId={workId}
      />
    </>
  );
};

export const pageQuery = graphql`
  query Work(
    $id: String!
    $perPage: Int!
    $skipItems: Int!
    $parentId: String!
    $primaryFilter: String!
    $secondaryFilter: String!
  ) {
    allWpProject(
      filter: {
        services: { nodes: { elemMatch: { slug: { glob: $primaryFilter } } } }
        industries: {
          nodes: { elemMatch: { slug: { glob: $secondaryFilter } } }
        }
      }
      sort: { order: DESC, fields: date }
      limit: $perPage
      skip: $skipItems
    ) {
      edges {
        node {
          id
          uri
          project {
            name
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 564
                    height: 571
                    transformOptions: { cropFocus: CENTER }
                  )
                }
                extension
                publicURL
              }
            }
          }
          services {
            nodes {
              name
            }
          }
        }
      }
    }
    wpPage(id: { eq: $parentId }) {
      work {
        bannerSubTitle
        bannerTitle
        bannerBackground {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            extension
            publicURL
          }
        }
        centeredContentTitle
        centeredContentSubTitle
        centeredContent
        workTitle
        workContent
        workBottomTitle
        workBottomSubtitle
        workBottomContent
      }
      title
      page {
        metaTitle
        metaDescription
        pageCssOverrides
        pageSchema
      }
    }
    wpService(id: { eq: $id }) {
      work {
        bannerSubTitle
        bannerTitle
        bannerBackground {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            extension
            publicURL
          }
        }
        centeredContentTitle
        centeredContentSubTitle
        centeredContent
        workTitle
        workContent
        workBottomContent
        workBottomTitle
        workBottomSubtitle
      }
      page {
        metaTitle
        metaDescription
        pageCssOverrides
        pageSchema
      }
    }
    wp {
      themeSettings {
        themeSettings {
          siteMetaTitle
          customCssOverrides
          customCssClasses
        }
      }
    }
  }
`;

export default Work;
