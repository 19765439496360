import React from "react";
import convertId from "../../utilities/convert-id";
import Content from "../utilities/content";
import { graphql, Link, useStaticQuery } from "gatsby";
import CmsImage from "../utilities/cms-image";
import Filter from "./filter";
import Pagination from "../utilities/pagination";
import ArrowWorkSliderRight from "../../images/arrow-work-slider-right.inline.svg";

const WorkElem = ({
  data,
  projects,
  totalPages,
  currentPage,
  baseUri,
  unfilteredUri,
  primaryFilter,
  secondaryFilter,
  workId,
}) => {
  const {
    allWpService: { nodes: services },
    /*allWpIndustry: { nodes: industries },*/
  } = useStaticQuery(
    graphql`
      query Filters {
        allWpService {
          nodes {
            id
            name
            slug
            projects {
              nodes {
                id
              }
            }
          }
        }
      }
    `
  );

  return (
    <section
      id={convertId(data.name)}
      className="work section bg-white px-x py-y-lg lg:pt-[55px] lg:pb-[145px]"
    >
      <div className="container-work">
        {data.title && (
          <Content className="typo-title mb-[25px]">{data.title}</Content>
        )}
        {data.content && (
          <Content className="typo-team-intro-content mb-[40px]">
            {data.content}
          </Content>
        )}
        <Filter
          items={services}
          unfilteredUri={unfilteredUri}
          active={primaryFilter}
          secondary={secondaryFilter}
          workId={workId}
        >
          Services
        </Filter>
        {/*<Filter
          items={industries}
          unfilteredUri={unfilteredUri}
          active={secondaryFilter}
          primary={primaryFilter}
          workId={workId}
        >
          Industries
        </Filter>*/}
        <div className="mt-[55px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[45px] mb-[40px]">
          {projects.length ? (
            projects.map(({ node: project }) => (
              <Link
                className="overflow-hidden group block relative"
                key={project.id}
                to={project.uri}
              >
                <CmsImage
                  className="transition group-hover:scale-125 w-full"
                  image={project.project.image}
                />
                <div
                  className="
                        absolute bottom-0 inset-x-0
                        after:bg-gray-work-slider-overlay after:mix-blend-multiply after:inset-0 after:absolute
                        before:bg-blue-gradient before:mix-blend-multiply before:inset-0 before:absolute before:opacity-0
                        before:transition after:transition
                        group-hover:before:opacity-100 group-hover:after:opacity-0
                      "
                >
                  <div className="relative z-10 pl-[19px] pr-[79px] pt-[8px] pb-[11px]">
                    {project.services.nodes.length > 0 && (
                      <div className="typo-work-slider-sub-title mb-[8px]">
                        {project.services.nodes.map((service) => (
                          <span className="after:content-['_|_'] last:after:content-none">
                            {service.name}
                          </span>
                        ))}
                      </div>
                    )}
                    <div className="typo-work-slider-title">
                      {project.project.name}
                    </div>
                    <ArrowWorkSliderRight className="right-[25px] bottom-[17px] absolute transition opacity-0 group-hover:opacity-100" />
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <div className="sm:col-span-2 lg:col-span-3">
              <p>
                There were no projects found for this combination of filters.
              </p>
            </div>
          )}
        </div>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          baseUri={baseUri}
          endId={workId}
        />
        <div className="work-btm py-y-lg">
          {data.btmTitle && (
            <Content className="typo-title mb-[39px]">{data.btmTitle}</Content>
          )}
          {data.btmSubtitle && (
            <Content className="typo-content-strong mb-[14px]">
              {data.btmSubtitle}
            </Content>
          )}
          {data.btmContent && (
            <Content className="typo-paragraph-text text-gray-3">
              {data.btmContent}
            </Content>
          )}
        </div>
      </div>
    </section>
  );
};

export default WorkElem;
